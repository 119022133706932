import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0, 0.6);
  z-index: 4;
`;

export const Clipboard = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 10px 20px;
  border-radius: 5px;
  input {
    padding: 0.6em;
    color: Black;
    border: 1px solid black;
    border-radius: 5px;
    width: 80%;
  }

  button {
    border: none;
    font-size: 16px;
    color: Blue;
    padding: 6px;
    background-color: white;
  }
`;
