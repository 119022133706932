import React from "react";
import { ColorRing } from "react-loader-spinner";
import Footer from "../components/footer";
import useFetch from "../utils/useFetch";

export default function FooterContainer() {
  const [videoBannerData, videoBannerFetching, videoBannerError] = useFetch({
    tableName: "dealer-A",
    fields: ["video_call_banner"],
    filterByFormula: "",
  });

  if (videoBannerFetching) {
    return <ColorRing
    visible={true}
    height="40"
    width="40"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
    />;
  }

  if (videoBannerData == "") {
    return null;
  }

  return (
    (videoBannerData && <Footer>
      <Footer.Image src={videoBannerData?.[0].video_call_banner?.[0].url} />
    </Footer>)
  );
}
