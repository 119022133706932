import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Container, Clipboard } from "./styles/sharelink";

export default function ShareLink({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

ShareLink.Clipboard = function SharelinkClipboard({ children, shareUrl }) {
  return (
    <Clipboard onClick={(e) => e.stopPropagation()}>
      <input type="text" value={shareUrl} readOnly />
      <CopyToClipboard text={shareUrl}>
        <button>COPY</button>
      </CopyToClipboard>
    </Clipboard>
  );
};
