import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 5px;
`;

export const Title = styled.h2`
  margin-bottom: 5px;
`;
