import React from "react";
import { Container, Title } from "./styles/sectionheader";

export default function SectionHeader({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

SectionHeader.Title = function SectionHeaderTitle({ children, ...props }) {
  return <Title {...props}>{children}</Title>;
};
