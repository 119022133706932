import { base } from "../config/airtable.config";
import { useState } from "react";
export const useVisit = ({ tableName, fields }) => {
  const [visitSubmitting, setVisitSubmitting] = useState(false);
  const [visitError, setVisitPostError] = useState(false);
  const [visitSuccess, setVisitSuccess] = useState(false);
  const visitHandler = () => {
    setVisitSubmitting(true);

    base(tableName).update(
      [
        { 
          fields,
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          setVisitPostError(err);
          setVisitSubmitting(false);
          return;
        }
        records.forEach(function (_record) {
          setVisitSuccess(true);
          setVisitSubmitting(false);
        });
      }
    );
  };
  return [visitHandler, visitSubmitting, visitError, visitSuccess];
};
