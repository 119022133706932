import moment from "moment";
import React, { useContext } from "react";
import { useEffect } from "react";
import ShareLink from "../components/sharelink";
import { useVisit } from "../utils/useVisit";
import { VideoContext } from "../utils/videoContext";

export default function Share({ shareUrl }) {
  const { setShareModal, toggleScrollLock } = useContext(VideoContext);
 
  const [visitHandler, visitSubmitting, visitError, visitSuccess] = useVisit({
    tableName: "dealer-A",
    fields: {
      user_activity_visit: "Share_click",
      uservisit_time: moment(Date.now()).format("MMMM Do YYYY, h:mm:ss a"),
    },
  });

  useEffect(()=>{
    visitHandler();    
  },[]);

  function closeModal() {
    setShareModal(false);
  }

  return (
    <ShareLink onClick={() => closeModal()}>
      <ShareLink.Clipboard shareUrl={shareUrl}></ShareLink.Clipboard>
    </ShareLink>
  );
}
