import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000;
  overflow-y: scroll;
  scroll-behavior: smooth;
  margin-bottom: 15px;
`;

export const DiscountTag = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px;
  background-image: linear-gradient(to right, #e4f1ff, #b6d5f7);
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }

  p {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const VideoElement = styled.iframe`
  width: 100%;
`;

export const VideoDetails = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  color: white;
  margin-bottom: 10px;

  h2 {
    margin-bottom: 10px;
  }

  .detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .product_specification {
    display: flex;
    align-items: center;
  }

  .product_specification .engine_power {
    border-right: 2px solid white;
    padding-right: 10px;
    margin-right: 10px;
  }

  .like-share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const RequestButton = styled.button`
  width: 100%;
  padding: 15px 25px;
  background-image: linear-gradient(to right, #e51836, #ff4e68);
  border: none;
  font-weight: 500;
  font-size: 16px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  &: hover {
    background-color: #e51836;
  }
`;
