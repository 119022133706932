import React from "react";
import HeaderContainer from "../container/Header";
import OfferSection from "../container/OfferSection";
import PosterContainer from "../container/Poster";
import PerfomanceVideoSection from "../container/PerfomanceVideoSection";
import CustomerReviews from "../container/CustomerReviews";
import TrendingImplements from "../container/TrendingImplements";
import LiveVideo from "../container/LiveVideo";
import ServiceInfoConatiner from "../container/ServiceInfoContainer";
import FooterContainer from "../container/Footer";
import { useEffect } from "react";
import moment from "moment";
import { useVisit } from "../utils/useVisit";
import { useLocation } from "react-router-dom";
import { usePost } from "../utils/usePost";

export default function Home() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dealer = queryParams.get("dealer");
  const base = queryParams.get("base");

    const [visitHandler, visitSubmitting, visitPostError, visitSuccess] = usePost({
        tableName: "User_Activity",
        fields: {
          user_activity_visit: "Home_Page",
          uservisit_time: moment(Date.now()).format("MMMM Do YYYY, h:mm:ss a"),
        },
      });
    
      useEffect(()=>{
        visitHandler();    
      },[]);



  return (
    <>
      <HeaderContainer />
      <PosterContainer  />
      <OfferSection />
      <PerfomanceVideoSection dealer={dealer} base={base} />
      <LiveVideo dealer={dealer} base={base} />
      <CustomerReviews dealer={dealer} base={base} />
      <ServiceInfoConatiner />
      <TrendingImplements dealer={dealer} base={base} />
      <FooterContainer />
    </>
  );
}
