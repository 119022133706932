import { ColorRing } from "react-loader-spinner";
import Header from "../components/header";
import useFetch from "../utils/useFetch";

export default function HeaderContainer() {
  const [headerdata, headerFetching, headerError] = useFetch({
    tableName: "dealer-A",
    fields: ["company_logo"],
    maxRecords: 1,
    filterByFormula: "",
  });

  if (headerFetching) {
    return <ColorRing
    visible={true}
    height="40"
    width="40"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
    />;
  }

  if (headerdata == "") {
    return null;
  }

  //   console.log(headerdata);
  return (
    <Header>
      <Header.Logo src={headerdata?.[0].company_logo?.[0].url} />
    </Header>
  );
}
