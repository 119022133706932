import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
`;

export const Message = styled.p`
  padding: 10px 5px;
  color: green;
  font-weight: 600;
`;
