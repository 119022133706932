import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #5c09b5;
  padding: 15px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Title = styled.h2`
  font-weight: 500;
  color: white;
  font-style: italic;
`;

export const Logo = styled.img`
  height: 70px;
  width: 90px;
`;

export const CardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
`;

export const Card = styled.div`
  position: relative;
  width: 175px;
  height: 175px;
  border-radius: 5px;
  background-image: url(${({ image }) => image});
  background-size: contain;
  margin: 0 10px 10px 0;
`;

export const TitleStrip = styled.div`
  position: absolute;
  width: 100%;
  overflow-wrap: break-word;
  background: rgba(0, 0, 0, 1);
  bottom: 0;
  text-align: center;
  padding: 5px 0;
  opacity: 0.5;
  flex-wrap: wrap;
`;

export const CardTitle = styled.p`
  color:   #e6e6e6;
  font-weight: 400;
  font-size: 20px;
`;
