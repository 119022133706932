import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
`;

export const GroupFields = styled.div`
  color: palevioletred;
  display: block;
  width: 300px;
  margin: 50px auto;
`;

export const Input = styled.input`
  padding: 0.5em;
  color: Black;
  border: 1px solid black;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 0.5em;
`;

export const Label = styled.label`
  margin-bottom: 0.5em;
  color: Black;
  display: block;
`;

export const Button = styled.button`
  background-color: #e8e6df;
  padding: 10px 20px;
  margin-right: 5px;
  border-radius: 5px;
  border: none;
  font-weight: 600;

  &:hover {
    background-color: #d1cfc7;
  }
`;
