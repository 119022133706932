import Airtable from "airtable";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

let baseID = params.base;


export const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_PRIVATE_KEY,
}).base(baseID);
//appNYSpc3uIGRCyh3 
//appYSLiCLbNe0Cqqw
