import { base } from "../config/airtable.config";
import { useState } from "react";
export const useUpdate = ({ tableName, id, fields }) => {
  const [updateSubmitting, setUpdateSubmitting] = useState(false);
  const [updatePostError, setUpdatePostError] = useState(false);
  const [updatesuccess, setUpdateSuccess] = useState(false);

  const updateHandler = () => {
    setUpdateSubmitting(true);
    //console.log("tableName",tableName,id,fields);

    base(tableName).update(
      [
        {
          id,
          fields,
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          setUpdatePostError(err);
          setUpdateSubmitting(false);
          return;
        }
        records.forEach(function (_record) {
          setUpdateSuccess(true);
          setUpdateSubmitting(false);
        });
      }
    );
  };
  return [updateHandler, updateSubmitting, updatePostError, updatesuccess];
};
