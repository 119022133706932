import { ColorRing } from "react-loader-spinner";
import Poster from "../components/poster";
import useFetch from "../utils/useFetch";

export default function PosterContainer({ posterprop }) {
  const [posterData, posterFetching, posterError] = useFetch({
    tableName: "dealer-A",
    fields: ["title_poster_img","sampark_kare"],
    maxRecords: 1,
    filterByFormula: "",
  });

//console.log(posterData);

  if (posterFetching) {
    return <ColorRing
    visible={true}
    height="40"
    width="40"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
    />;;
  }

  if (posterData == "") {
    return null;
  }

  return (
    <Poster>
      <Poster.Contact href={`tel: ${posterData?.[0].sampark_kare}`}>
        <Poster.Image src={posterData?.[0].title_poster_img?.[0].url} />
      </Poster.Contact>
    </Poster>
  );
}
