import React from "react";
import {
  Container,
  Navigate,
  LiveSymbol,
  Title,
  Image,
  WatchNow,
} from "./styles/liveposter";

export default function LivePoster({ children, ...props }) {
  // console.log("liveimage",props);
  return <Container {...props}>{children}</Container>;
}

LivePoster.Navigate = function LivePosterNavigate({ children, ...props }) {
  return <Navigate {...props}>{children}</Navigate>;
};

LivePoster.Title = function LivePosterTitle({ children, ...props }) {
  return <Title {...props}>{children}</Title>;
};

LivePoster.LiveSymbol = function LivePosterLiveSymbol({ children, ...props }) {
  return (
    <LiveSymbol {...props}>
      <div className="circle"></div>
      <p>LIVE</p>
    </LiveSymbol>
  );
};

LivePoster.Image = function LivePosterImage({ ...props }) {
  return <Image {...props} />;
};

LivePoster.WatchNow = function LiverPosterEWatchNow({ children, ...props }) {
  return (
    <WatchNow {...props}>
      <p>Watch Now</p>
      <img src="./assets/icons/arrow_forward.png" />
    </WatchNow>
  );
};
