import React from "react";
import { Container, Logo } from "./styles/header";

export default function Header({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

Header.Logo = function HeaderLogo({ ...props }) {
  return <Logo {...props} />;
};
