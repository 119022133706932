import React from "react";
import { Container, GroupFields, Input, Label, Button } from "./styles/form";

export default function Form({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

Form.GroupFields = function FormGroupFields({ children, ...props }) {
  return <GroupFields {...props}>{children}</GroupFields>;
};

Form.Input = function FormInput({ ...props }) {
  return <Input {...props} />;
};

Form.Label = function FormLabel({ children, ...props }) {
  return <Label {...props}>{children}</Label>;
};

Form.Button = function FormButton({ children, ...props }) {
  return <Button {...props}>{children}</Button>;
};
