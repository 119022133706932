import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import useFetch from "./useFetch";

const VideoContext = React.createContext({});

function VideoContextProvider({ children }) {
  const [show, setShow] = useState(false);
  const [like, setLike] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [formStatus, setFormStatus] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [onIndex, setOnIndex] = useState("");
  const [shareUrl, setShareUrl] = useState("");

  const toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };

  function modalForm(index) {
    setShow(true);
    setOnIndex(index);
    // console.log("show",show,index);
  }

  function shareButton(url, index) {
    setOnIndex(index);
    setShareModal(true);
    setShareUrl(url);
  }

  const [performanceData, performanceFetching, performanceError] = useFetch({
    tableName: "dealer-A",
    fields: [
      "id",
      "performance_headline",
      "performance_video_title",
      "performance_yt_link",
      "performance_discount",
      "performance_likes",
      "performance_engine",
      "performance_wheels",
    ],
    filterByFormula: "",
  });

  const [liveLinkData, liveLinkFetching, liveLinkError] = useFetch({
    tableName: "dealer-A",
    fields: ["live_yt_title", "live_yt_link"],
    filterByFormula: "",
  });

  const [reviewsData, reviewsFetching, reviewsError] = useFetch({
    tableName: "dealer-A",
    fields: [
      "id",
      "reviews_headline",
      "reviews_video_title",
      "reviews_yt_link",
      "reviews_discount",
      "reviews_likes",
      "reviews_engine",
      "reviews_wheels",
    ],
    filterByFormula: "",
  });

  const [servicesData, servicesFetching, servicesError] = useFetch({
    tableName: "dealer-A",
    fields: [
      "service_info_title",
      "service_info_complogo",
      "service_info_img",
      "service_info_img_title",
    ],
    filterByFormula: "",
  });

  const [implementsData, implementsFetching, implementsError] = useFetch({
    tableName: "dealer-A",
    fields: [
      "id",
      "implements_headline",
      "implements_video_title",
      "implements_yt_link",
      "implements_discount",
      "implements_likes",
      "implements_engine",
      "implements_wheels",
    ],
    filterByFormula: "",
  });

  if (
    performanceFetching ||
    liveLinkFetching ||
    reviewsFetching ||
    servicesFetching ||
    implementsFetching
  ) {
    return <ColorRing
    visible={true}
    height="40"
    width="40"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
    />;;
  }

  let videos = [];

  for (const performance of performanceData) {
    if (!performance.performance_yt_link) continue;
    videos.push({
      type: "performance",
      id: performance.id,
      title: performance.performance_video_title,
      headline: performance.performance_headline,
      ytlink: performance.performance_yt_link,
      likes: performance.performance_likes,
      discount: performance.performance_discount,
      engine: performance.performance_engine,
      wheels: performance.performance_wheels,
      prefix: "performance_",
    });
  }

  for (const reviews of reviewsData) {
    if (!reviews.reviews_yt_link) continue;
    videos.push({
      type: "customer",
      id: reviews.id,
      title: reviews.reviews_video_title,
      headline: reviews.reviews_headline,
      ytlink: reviews.reviews_yt_link,
      likes: reviews.reviews_likes,
      discount: reviews.reviews_discount,
      engine: performance.reviews_engine,
      wheels: performance.reviews_wheels,
      prefix: "reviews_",
    });
  }

  for (const implement of implementsData) {
    if (!implement.implements_yt_link) continue;
    videos.push({
      type: "trending",
      id: implement.id,
      title: implement.implements_video_title,
      headline: implement.implements_headline,
      ytlink: implement.implements_yt_link,
      likes: implement.implements_likes,
      discount: implement.implements_discount,
      engine: performance.implements_engine,
      wheels: performance.implements_wheels,
      prefix: "implements_",
    });
  }

  for (const live of liveLinkData) {
    if (!live.live_yt_link) continue;
    videos.push({
      type: "live",
      title: live.live_yt_title,
      ytlink: live.live_yt_link,
    });
  }

  return (
    <VideoContext.Provider
      value={{
        performanceData,
        liveLinkData,
        reviewsData,
        servicesData,
        implementsData,
        toggleScrollLock,
        videos,
        show,
        setShow,
        name,
        setName,
        phone,
        setPhone,
        formStatus,
        shareModal,
        shareUrl,
        modalForm,
        onIndex,
        setOnIndex,
        shareButton,
        setShareModal,
        setFormStatus,
        like,
        setLike,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
}

export { VideoContextProvider, VideoContext };
