import React from "react";
import {
  Container,
  PlayIcon,
  ThumbNail,
  Navigation,
  Card,
} from "./styles/videocard";

export default function VideoCard({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

VideoCard.Card = function VideoCardCard({ children, ...props }) {
  return <Card {...props}>{children}</Card>;
};

VideoCard.PlayIcon = function VideoCardPlayIcon({ ...props }) {
  return <PlayIcon {...props} />;
};

VideoCard.ThumbNail = function VideoCardThumbNail({ ...props }) {
  return <ThumbNail {...props} />;
};

VideoCard.Navigation = function VideoCardNavigation({ children, ...props }) {
  return <Navigation {...props}>{children}</Navigation>;
};
