import React from "react";
import {
  Container,
  Title,
  Amount,
  UnderLine,
  ProdName,
  Image,
  Circle,
  Main,
  Card,
} from "./styles/offercard";

export default function OfferCard({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

OfferCard.Card = function OfferCardCard({ children, ...props }) {
  return <Card {...props}>{children}</Card>;
};

OfferCard.Title = function OfferCardTitle({ children, ...props }) {
  return <Title {...props}>{children}</Title>;
};

OfferCard.Amount = function OfferCardAmount({ children, ...props }) {
  return <Amount {...props}>{children}</Amount>;
};

OfferCard.UnderLine = function OfferCardUnderLine({ children, ...props }) {
  return <UnderLine {...props}>{children}</UnderLine>;
};

OfferCard.ProdName = function OfferCardProdName({ children, ...props }) {
  return <ProdName {...props}>{children}</ProdName>;
};

OfferCard.Image = function OfferCardImage({ children, ...props }) {
  return <Image {...props}>{children}</Image>;
};

OfferCard.Circle = function OfferCardCircle({ children, ...props }) {
  return <Circle {...props}>{children}</Circle>;
};

OfferCard.Main = function OfferCardMain({ children, ...props }) {
  return <Main {...props}>{children}</Main>;
};
