import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  overflow: scroll;
`;

export const Card = styled.div`
  width: 100%;
  min-height: 250px;
  max-height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-right: 20px;
  :nth-child(odd) {
    background-color: #f5cd49;
    color: #805500;
    .circle {
      background-color: #ede7a6;
    }
  }
  :nth-child(even) {
    background-color: #0099cc;
    color: #ffffff;
    .circle {
      background-color: #00b8e6;
    }
  }
`;

export const Main = styled.div`
  padding: 10px;
`;

export const Title = styled.h3`
  font-weight: 500;
  margin-bottom: 5px;
`;

export const Amount = styled.p`
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UnderLine = styled.div`
  width: 80px;
  height: 1px;
  background-color: black;
  margin-bottom: 5px;
`;

export const ProdName = styled.h3`
  font-weight: 500;
  z-index: 1;
`;

export const Image = styled.img`
  width: 200px;
  height: 100px;
  justify-self: flex-end;
  z-index: 1;
`;

export const Circle = styled.div`
  position: absolute;
  bottom: 0;
  min-height: 100px;
  width: 100%;
  border-radius: 150px 150px 0 0;
`;
