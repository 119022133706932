import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: black;
  position: relative;
  padding: 20px;
  background-image: url(${({ thumbnail }) => thumbnail});
  background-size: cover;
`;

export const Navigate = styled(Link)`
  text-decoration: none;
`;

export const Title = styled.h2`
  color: white;
  font-weight: 500;
  margin: 10px 0;
`;

export const LiveSymbol = styled.div`
  width: 70px;
  height: 30px;
  background-color: red;
  position: relative;
  margin-right: 10px;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;

  .circle {
    position: absolute;
    height: 15px;
    width: 15px;
    align-items: center;
    border-radius: 50%;
    background-color: white;
  }

  p {
    color: white;
    padding-left: 20px;
  }
`;

export const Image = styled.img``;

export const WatchNow = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 3px solid red;
  padding: 10px;
  justify-content: space-between;

  p {
    color: red;
    font-weight: 700;
  }
`;
