import React from "react";
import { Container, Image } from "./styles/footer";

export default function Footer({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

Footer.Image = function FooterImage({ ...props }) {
  return <Image {...props} />;
};
