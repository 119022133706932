import React, { useContext } from "react";
import LivePoster from "../components/liveposter";
import useGetVideoData from "../utils/useGetVideoData";
import { VideoContext } from "../utils/videoContext";

export default function LiveVideo({dealer,base}) {
  const { liveLinkData } = useContext(VideoContext);
  const [videoData, loading] = useGetVideoData({
    urlData: liveLinkData,
    urlFieldName: "live_yt_link",
  });

  //console.log(`livevideoData`,videoData);

  if (videoData?.length === 0) {
    return null;
  }

  return (
    <div style={{ display: "flex", overflow: "scroll" }}>
      {videoData.map((item, index) => (
        <LivePoster key={index} thumbnail={item.thumbnail_url}>
          <LivePoster.Navigate to={`./video?type=live&dealer=${dealer}&base=${base}`}>
            <LivePoster.LiveSymbol />
            <LivePoster.Title>{liveLinkData?.[0]?.live_yt_title}</LivePoster.Title>
            <LivePoster.Image />
            <LivePoster.WatchNow />
          </LivePoster.Navigate>
        </LivePoster>
      ))}
    </div>
  );
}
