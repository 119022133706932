import React, { useContext } from "react";
import ServiceInfo from "../components/serviceinfo";
import { VideoContext } from "../utils/videoContext";

export default function ServiceInfoConatiner() {
  const { servicesData } = useContext(VideoContext);
  // console.log("servicesData",servicesData);

  if (servicesData.length === 0) {
    return null;
  }

  return (
    <ServiceInfo>
      <ServiceInfo.HeaderContainer>
        <ServiceInfo.Title>
          {servicesData?.[0]?.service_info_title}
        </ServiceInfo.Title>
        <ServiceInfo.Logo src={servicesData?.[0].service_info_complogo?.[0].url} />
      </ServiceInfo.HeaderContainer>
      <ServiceInfo.CardContainer>
        {servicesData.map(
          (item, index) =>
            (Object.keys(item).length !== 0 && item.service_info_img?.[0].url && item.service_info_img_title) && (
                
              <ServiceInfo.Card
                key={index}
                image={item?.service_info_img?.[0].url}
              >
                <ServiceInfo.TitleStrip>
                  <ServiceInfo.CardTitle>
                    {item?.service_info_img_title}
                  </ServiceInfo.CardTitle>
                </ServiceInfo.TitleStrip>
              </ServiceInfo.Card>
            )
        )}
      </ServiceInfo.CardContainer>
    </ServiceInfo>
  );
}
