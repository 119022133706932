import React, { useState, useEffect, useRef } from "react";
import { base } from "../config/airtable.config";
const useFetch = ({
  tableName,
  fields,
  filterByFormula = "",
  maxRecords = 1000,
  dependencyState = null,
}) => {
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const ref = useRef(false);
  useEffect(() => {
    if (ref.current) return;
    setFetching(true);
    base(tableName)
      .select({
        maxRecords: 100,
        view: "Grid view",
        fields,
        filterByFormula,
        maxRecords,
      })
      .eachPage(
        function page(records, _fetchNextPage) {
          let recordsData = records.map((item) => {
            return { ...item.fields };
          });

          // console.log(recordsData);
          setData(recordsData);

          setError(false);
          setFetching(false);
        },
        function done(err) {
          setError(false);
          setData(null);
          setFetching(false);
        }
      );

    ref.current = true;
  }, [dependencyState]);
  return [data, fetching, error];
};

export default useFetch;
