import moment from "moment";
import React, { useContext, useEffect } from "react";
import Form from "../components/form";
import ModalWrapper from "../components/modal";
import Status from "../components/status";
import { usePost } from "../utils/usePost";
import { useVisit } from "../utils/useVisit";
import { VideoContext } from "../utils/videoContext";
import Swal from "sweetalert2";
import { ColorRing } from "react-loader-spinner";

export default function PopupForm() {
  const {
    setShow,
    toggleScrollLock,
    name,
    setName,
    phone,
    setPhone,
    formStatus,
    setFormStatus,
  } = useContext(VideoContext);
  

  function handleSubmit(e) {
    e.preventDefault();
    postHandler();
    setFormStatus(true);
  }

  const [visitHandler, visitSubmitting, visitError, visitSuccess] = usePost({
    tableName: "User_Activity",
    fields: {
      user_activity_visit: "Form_Visit",
      uservisit_time: moment(Date.now()).format("MMMM Do YYYY, h:mm:ss a"),
    },
  });

useEffect(()=>{
    visitHandler();
},[])

  const [postHandler, submitting, postError, success] = usePost({
    tableName: "dealer-A",
    fields: {
      Name: name,
      Mobile_Number: phone,
    },
  });

  useEffect(() => {
    if(success){ 
    Swal.fire({
      icon: "success",
      confirmButtonColor: '#652078',
      text: "SuccessFully submitted"
    }).then((res) => {
      if (res) {
        setShow(false);
        setName('')
        setPhone('')
      }
    })}
  },[submitting])


  function closeModal() {
    setShow(false);
    setFormStatus(false);
    setName("");
    setPhone("");
  }

  return (
    <>
      <ModalWrapper onClick={closeModal}>
        <ModalWrapper.Modal>
          {/* {formStatus && (
            <Status>
              <Status.Message>Succesfully submitted</Status.Message>
            </Status>
          )} */}
          <Form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
            <Form.GroupFields>
              <Form.Label>Name</Form.Label>
              <Form.Input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <Form.Label>Mobile No</Form.Label>
              <Form.Input
                type="number"
                name="phone"
                pattern="\d{10}"
                maxlength="10"
                minlength="10"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              {submitting ? <ColorRing
    visible={true}
    height="40"
    width="40"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
    /> :<>
    <Form.Button onClick={closeModal}>Close</Form.Button>
              <Form.Button>Submit</Form.Button>
    </>  }
            </Form.GroupFields>
          </Form>
        </ModalWrapper.Modal>
      </ModalWrapper>
    </>
  );
}
