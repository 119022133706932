import React, { useContext } from "react";
import { VideoContext } from "../../utils/videoContext";
import {
  Container,
  DiscountTag,
  VideoElement,
  VideoDetails,
  RequestButton,
} from "./styles/videoplay";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { GrLinkPrevious } from "react-icons/gr";
import { Link, useLocation } from "react-router-dom";

export default function VideoPlay({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

VideoPlay.DiscountTag = function VideoPlayDiscountTag({
  children,
  discount,
  ...props
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dealer = queryParams.get("dealer");
  const base = queryParams.get("base");


  return (
    <DiscountTag {...props}>
      <Link to={`/?dealer=${dealer}&base=${base}`}>
        <GrLinkPrevious style={{ width: "20px" }} />
      </Link>
      <div style={{ height: "40px" }}>
        {discount && <img src="./assets/icons/Offer.png" alt="discount icon" />}
      </div>
      <div className="title">
        <p>{discount}</p>
      </div>

      {children}
    </DiscountTag>
  );
};

VideoPlay.VideoElement = function VideoPlayVideoElement({ children, src }) {
  return <VideoElement src={src}>{children}</VideoElement>;
};

VideoPlay.VideoDetails = function VideoPlayVideoDetails({
  children,
  engine,
  wheels,
  title,
  likes,
  likeCount,
  shareButton,
  isActive,
  ...props
}) {
  return (
    <VideoDetails>
      <div className="detail">
        <div>
          <h2>{title}</h2>
          {(engine || wheels) && (
            <div className="product_specification">
              <img src="./assets/icons/engine.png" alt="engine" />
              <p className="engine_power">{engine}</p>
              <img src="./assets/icons/wheel.png" alt="wheels" />
              <p>{wheels} Wheel Drive</p>
            </div>
          )}
        </div>
        <div className="like-share">
          {isActive ? (
            <FaHeart onClick={likeCount} style={{ color: "red" }} />
          ) : (
            <FaRegHeart onClick={likeCount} style={{ color: "white" }} />
          )}
          <p className="count" style={{ color: "white" }}>
            {likes}
          </p>
          <p className="sharelink" onClick={shareButton}>
            <img src="./assets/icons/share.png" alt="share Icon" />
          </p>
        </div>
      </div>

      {children}
    </VideoDetails>
  );
};

VideoPlay.RequestButton = function VideoPlayRequestButton({
  children,
  ...props
}) {
  return <RequestButton {...props}>{children}</RequestButton>;
};
