import React from "react";
import {
  Container,
  HeaderContainer,
  Title,
  Logo,
  CardContainer,
  Card,
  CardTitle,
  TitleStrip,
} from "./styles/serviceinfo";

export default function ServiceInfo({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

ServiceInfo.HeaderContainer = function ServiceInfoHeaderContainer({
  children,
  ...props
}) {
  return <HeaderContainer {...props}>{children}</HeaderContainer>;
};

ServiceInfo.Title = function ServiceInfoTitle({ children, ...props }) {
  return <Title {...props}>{children}</Title>;
};

ServiceInfo.Logo = function ServiceInfoLogo({ children, ...props }) {
  return <Logo {...props}>{children}</Logo>;
};

ServiceInfo.CardContainer = function ServiceInfoCardContainer({
  children,
  ...props
}) {
  return <CardContainer {...props}>{children}</CardContainer>;
};

ServiceInfo.Card = function serviceinfoCard({ children, ...props }) {
  return <Card {...props}>{children}</Card>;
};

ServiceInfo.TitleStrip = function ServiceInfoTitleStrip({
  children,
  ...props
}) {
  return <TitleStrip {...props}>{children}</TitleStrip>;
};

ServiceInfo.CardTitle = function serviceinfoCardTitle({ children, ...props }) {
  return <CardTitle {...props}>{children}</CardTitle>;
};
