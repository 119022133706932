import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 150px;
  position: relative;
  border-radius: 10px;
  margin-right: 10px;
`;

export const Card = styled.iframe``;

export const PlayIcon = styled.img`
  position: absolute;
`;

export const ThumbNail = styled.img`
  width: 150px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
`;

export const Navigation = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
