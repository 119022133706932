import React, { useContext } from "react";
import BottomHighlightLine from "../components/bottomhighlight";
import SectionHeader from "../components/sectionheader";
import Section from "../components/section";
import VideoCard from "../components/videocard";
import { VideoContext } from "../utils/videoContext";
import useGetVideoData from "../utils/useGetVideoData";


export default function CustomerReviews({ reviewsprop,dealer,base }) {
  const { reviewsData } = useContext(VideoContext);

  const [videoData, loading] = useGetVideoData({
    urlData: reviewsData,
    urlFieldName: "reviews_yt_link",
  });



  //console.log("videoData",videoData);
  if (videoData?.length === 0) {
    return null;
  }

  return (
    <Section>
      <SectionHeader>
        <SectionHeader.Title>
          {reviewsData?.[0]?.reviews_headline}
        </SectionHeader.Title>
        <BottomHighlightLine></BottomHighlightLine>
      </SectionHeader>
      <div style={{ display: "flex", overflow: "scroll" }}>
        {videoData.map((item, index) => (
          <VideoCard key={index}>
            <VideoCard.Navigation to={`./video?type=customer&dealer=${dealer}&base=${base}`}>
              <VideoCard.PlayIcon src="./assets/icons/videoplay.png" />
              <VideoCard.ThumbNail src={item.thumbnail_url} />
            </VideoCard.Navigation>
          </VideoCard>
        ))}
      </div>
    </Section>
  );
}
