import React from "react";
import { Container, Modal, CloseButton } from "./styles/modal";

export default function ModalWrapper({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

ModalWrapper.Modal = function ModalWrapperModal({ children, ...props }) {
  return <Modal {...props}>{children}</Modal>;
};

ModalWrapper.CloseButton = function ModalWrapperCloseButton({
  children,
  ...props
}) {
  return <CloseButton {...props}>{children}</CloseButton>;
};
