import React, { useContext, useEffect, useState } from "react";
import VideoPlay from "../components/videoplay";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { VideoContext } from "../utils/videoContext";
import PopupForm from "../container/PopupForm";
import { useUpdate } from "../utils/useUpdate";
import Share from "../container/Share";
import moment from "moment/moment";
import { useVisit } from "../utils/useVisit";
import { usePost } from "../utils/usePost";

export default function Video() {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [prefix, setPrefix] = useState("");
  const [updateId, setUpdateId] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const videoType = queryParams.get("type");
  const {
    videos,
    show,
    onIndex,
    like,
    setLike,
    setOnIndex,
    modalForm,
    shareButton,
    shareUrl,
    shareModal,
  } = useContext(VideoContext);

  const filterVideos = videos.filter((video) => video.type === videoType);
  const [updateHandler, updateSubmitting, updateError, updateSuccess] = useUpdate({
    tableName: "dealer-A",
    id: updateId,
    fields: {
      [prefix]: like,
    },
  });


  const [visitHandler, visitSubmitting, visitError, visitSuccess] = usePost({
    tableName: "User_Activity",
    fields: {
      user_activity_visit: videoType,
      uservisit_time: moment(Date.now()).format("MMMM Do YYYY, h:mm:ss a"),
    },
  });

  useEffect(()=>{
    visitHandler();    
  },[]);

  useEffect(() => {
    updateHandler();
    //console.clear();
  }, [like]);

  function likeCount(updateId, likec, prefix, index) {
    setLike(likec);
    if (!isActive) {
      setPrefix(`${prefix}likes`);
      setUpdateId(updateId);
      setLike((prev) => prev + 1);
      setIsActive(true);
      setOnIndex(index);
    } else {
      //console.log("likec",updateId,like,likec,`${prefix}likes`);
      setPrefix(`${prefix}likes`);
      setUpdateId(updateId);
      setLike((prev) => prev - 1);
      setIsActive(false);
    }
  }

  function handleScroll(e) {
    setOnIndex(e.target.id);
  }

  //console.log("filterVideos",filterVideos)
  return (
    <div>
      {filterVideos.map(
        (item, index) =>
          Object.keys(item).length > 0 && (
            <VideoPlay key={index} onScroll={handleScroll} id={index}>
              <div>
                {videoType !== "live" && (
                  <VideoPlay.DiscountTag discount={item.discount} />
                )}

                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                  }}
                >
                  <ReactPlayer
                    url={item.ytlink}
                    loop
                    playsinline={true}
                    playing={index == onIndex ? true : false}
                    controls={false}
                    width="100%"
                    height="100%"
                  />
                </div>
                <VideoPlay.VideoDetails
                  engine={item.engine}
                  wheels={item.wheels}
                  title={item.title}
                  likes={item.likes}
                  likeCount={() =>
                    likeCount(item.id, item.likes, item.prefix, index)
                  }
                  isActive={index === onIndex ? true : false}
                  shareButton={() => shareButton(item.ytlink, index)}
                >
                  {videoType != "live" && (
                    <VideoPlay.RequestButton onClick={() => modalForm(index)}>
                      REQUEST QUOTE
                    </VideoPlay.RequestButton>
                  )}
                </VideoPlay.VideoDetails>
              </div>
              {index === onIndex && show && <PopupForm />}
              {index === onIndex && shareModal && <Share shareUrl={shareUrl} />}
            </VideoPlay>
          )
      )}
    </div>
  );
}
