import React from "react";
import BottomHighlight from "../components/bottomhighlight";
import OfferCard from "../components/offercard";
import SectionHeader from "../components/sectionheader";
import Section from "../components/section";
import useFetch from "../utils/useFetch";
import { FaRupeeSign } from "react-icons/fa";
import { ColorRing } from "react-loader-spinner";

export default function OfferSection() {
  const [offersData, offersFetching, offersError] = useFetch({
    tableName: "dealer-A",
    fields: [
      "offer_headline",
      "offer_title",
      "offer_product_name",
      "offer_image",
      "offer_amount",
    ],
    filterByFormula: "",
  });
  //console.log("offersData",offersData);
  if (offersFetching) {
    return <ColorRing
    visible={true}
    height="40"
    width="40"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
    />;;
  }

  if (offersData.length === 0) {
    return null;
  }

  return (
    <Section>
      <SectionHeader>
        <SectionHeader.Title>
          {offersData?.[0]?.offer_headline}
        </SectionHeader.Title>
        <BottomHighlight></BottomHighlight>
      </SectionHeader>
      <OfferCard>
        {offersData.map(
          (item, index) =>
            Object.keys(item).length > 0 && (
              <OfferCard.Card key={index}>
                <OfferCard.Circle className="circle"></OfferCard.Circle>
                {item.hasOwnProperty("offer_title") && (
                  <OfferCard.Title>{item.offer_title}</OfferCard.Title>
                )}
                {item.hasOwnProperty("offer_amount") && (
                  <OfferCard.Amount>
                    {/* <FaRupeeSign /> */}
                    {item.offer_amount}
                  </OfferCard.Amount>
                )}
                <OfferCard.UnderLine></OfferCard.UnderLine>
                {item.hasOwnProperty("offer_product_name") && (
                  <OfferCard.ProdName>
                    {item.offer_product_name}
                  </OfferCard.ProdName>
                )}
                {item.hasOwnProperty("offer_image") && (
                  <OfferCard.Image src={item.offer_image[0].url} />
                )}
              </OfferCard.Card>
            )
        )}
      </OfferCard>
    </Section>
  );
}
