import axios from "axios";
import React, { useState, useEffect, useRef } from "react";

const useGetVideoData = ({ urlData, urlFieldName }) => {
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const ref = useRef(false);
  //console.log("urlData11",urlData[0][1]);
  useEffect(() => {
    if (ref.current) return;
    setLoading(true);
    fetchUrl();
    ref.current = true;
  }, []);

  //  console.log("urlDataurlFieldName",urlData,urlFieldName);

  const fetchUrl = async () => {
    const urlPromises = [];
    for (const url of urlData) {
      if (url[urlFieldName]) {
        const promise = axios.get(
          `https://www.youtube.com/oembed?url=${url[urlFieldName]}`
        );
        urlPromises.push(promise);
      }
    }
    const resolvedStories = await Promise.all(urlPromises);
    const data = resolvedStories.map((response, index) => ({
      ...response.data,
      url: urlData[index][urlFieldName],
    }));
    //console.log("data",data);
    setVideoData(data);
    setLoading(false);
  };

  return [videoData, loading];
};

export default useGetVideoData;
